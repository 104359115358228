import axios from "axios";
import React, { useMemo } from "react";
import { Card, Container, Stack, Box, Typography, CircularProgress, CardContent, Switch, FormControlLabel, Alert, Button, Autocomplete, TextField } from "@mui/material";
import StoreSelector from "./../StoreSelector";
import { useParams, useSearchParams } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { LoadingButton } from "@mui/lab";
import { clone, cloneDeep, debounce } from "lodash";
import DecoStyleTable from "./DecoStyleTable";
import { SERVICE_URL } from "../App";
import DecoManagementCard from "./DecoManagementCard";
import * as AdvanceManager from "./../managers/AdvanceManager";
import * as SyncoreManager from "./../managers/SyncoreManager";
import * as InksoftDecorationManager from "./../managers/InksoftDecorationManager";
import { Timestamp, serverTimestamp } from "firebase/firestore";

const defaultOption = {
    "active": false,
    "overrides": {},
    "options": [{
        "decorationVendor": 95720,
        "advanceArtworkAccountId": null,
        "advanceArtworkId": null,
        "advanceArtworkVariationId": null,
        "decorationMethod": "Embroidery",
        "decorationLocation": "Left Chest",
        "decorationSize": '4"',
        "stitchCount": "0",
        "decorationColor": "",
        "runChargeCost": 5,
        "designName": "",
        "comment1": "",
        "comment2": "",
        "comment3": "",
    }]
};

function DecoManagerPage() {
    let { domainId } = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    let [art, setArt] = React.useState(null);
    let [personalizations, setPersonalizations] = React.useState(null);
    let [legacy, setLegacy] = React.useState(undefined);
    let [legacySupData, setLegacySupData] = React.useState(null);
    let [error, setError] = React.useState(null);
    let [submitError, setSubmitError] = React.useState(null);
    let [supData, setSupData] = React.useState([]);
    let [pSupData, setPSupData] = React.useState([]);
    let [submitting, setSubmitting] = React.useState(false);
    let [suppliers, setSuppliers] = React.useState([]);
    let [advanceAccounts, setAdvanceAccounts] = React.useState([]);
    let [advanceAccountInputValue, setAdvanceAccountInputValue] = React.useState("");
    let [loadingDeco, setLoadingDeco] = React.useState(false);
    let [loadingSuppliers, setLoadingSuppliers] = React.useState(false);
    let [loadingAccountArtwork, setAccountArtwork] = React.useState(false);
    let [loadingAdvanceAccounts, setLoadingAdvanceAccounts] = React.useState(false);
    let [loadingSupData, setLoadingSupData] = React.useState(false);
    let [activateAuto, setActiveAuto] = React.useState(true);
    let [decoCostIncluded, setDecoCostIncluded] = React.useState(false);
    let [selectedAdvanceAccountId, setSelectedAdvanceAccountId] = React.useState(null);
    let [selectedAdvanceAccountName, setSelectedAdvanceAccountName] = React.useState(null);
    let [clipboard, setClipboard] = React.useState([]);
    let [decorationData, setDecorationData] = React.useState(null);
    let [advanceAccountArtwork, setAdvanceAccountArtwork] = React.useState(null);
    let [disabledAdvanceAccountSelected, setDisabledAdvanceAccountSelected] = React.useState(false);
    let [serviceData, setServiceData] = React.useState(null);
    let [saveMessage, setSaveMessage] = React.useState(null);
    const [openStyleDialog, setOpenStyleDialog] = React.useState(false);
    const auth = getAuth();

    React.useEffect(() => {
        setArt(null);
        setPersonalizations(null);
        setLegacy(undefined);
        setSupData([]);
        setPSupData([]);
        setError(null);
        setActiveAuto(true);
        setDecoCostIncluded(false);
        setDecorationData(null);
        setAdvanceAccountInputValue("");
        setAdvanceAccountArtwork(null);
        setSelectedAdvanceAccountId(null);
        setSelectedAdvanceAccountName(null);
        setSaveMessage(null);

        const getDecoInfo = async () => {
            try {
                setLoadingDeco(true);
                setLoadingSupData(true);
                const auth = getAuth();
                const token = await auth.currentUser.getIdToken();
                let response = await axios.get(
                    `${SERVICE_URL}/inksoft/getStoreDecoInfo`,
                    {
                        params: {
                            "domainId": domainId,
                            "inksoftStoreUrl": searchParams.get("inksoftStoreUrl")
                        },
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }
                    }
                );
                let data = response.data;
                console.log(data["data"]["art"]);
                setArt(data["data"]["art"].sort((a, b) => b.styles.length - a.styles.length));
                setPersonalizations(data["data"]["personalizations"]);
                setLegacy({
                    "name": "Legacy",
                    "styles": data["data"]["legacy"]
                });
            } catch (e) {
                console.log(e);
                setError(e.toString());
            } finally {
                setLoadingDeco(false);
            }
        }

        const getData = async () => {
            try {
                setLoadingSupData(true);
                const decoData = await InksoftDecorationManager.getInksoftDecorationData(domainId, searchParams.get("inksoftStoreUrl"));
                setDecorationData(decoData.data() ?? {});
            } catch (e) {
                console.log(e);
                setError(e.toString());
            } finally {
                setLoadingSupData(false);
            }
        };

        const getServiceData = async () => {
            let serviceData = await InksoftDecorationManager.getServiceDoc(domainId);
            setServiceData(serviceData);
        };

        if (searchParams.get("inksoftStoreUrl") !== null) {
            console.log(searchParams.get("inksoftStoreUrl"));
            console.log("requests");
            getDecoInfo();
            getData();
            getServiceData();
        }
    }, [searchParams]);

    // React.useEffect(() => {
    //     const getSuppliers = async () => {
    //         try {
    //             setLoadingSuppliers(true);
    //             let suppliers = await SyncoreManager.getSuppliers(domainId);
    //             setSuppliers(suppliers);
    //             setLoadingSuppliers(false);
    //         } catch (e) {
    //             console.log(e);
    //             setError(e.toString());
    //         } finally {
    //             setLoadingSuppliers(false);
    //         }
    //     };
    //     if (suppliers.length === 0) {
    //         getSuppliers();
    //     }
    // }, [suppliers.length]);

    const getAdvanceAccounts = useMemo(() => 
        debounce(
            async (name) => {
                try {
                    setLoadingAdvanceAccounts(true);
                    const accounts = await AdvanceManager.getAccounts(domainId, name);
                    setAdvanceAccounts(accounts);
                } catch (e) {
                    console.log(e);
                    setError(e.toString());
                } finally {
                    setLoadingAdvanceAccounts(false);
                }
            }, 400
        ), []
    );

    React.useEffect(() => {    
        getAdvanceAccounts(advanceAccountInputValue);
    }, [advanceAccountInputValue, getAdvanceAccounts]);

    React.useEffect(() => {    
        const getAdvanceAccountArtwork = async (accountId) => {
            try {
                setLoadingAdvanceAccounts(true);                
                const artwork = await AdvanceManager.getAccountArtwork(domainId, accountId);
                setAdvanceAccountArtwork(artwork);
            } catch (error) {
                console.error(error);
            } finally {
                setLoadingAdvanceAccounts(false);
            }
        };
        setAdvanceAccountArtwork(null);
        if (selectedAdvanceAccountId !== null) {
            getAdvanceAccountArtwork(selectedAdvanceAccountId);
        } 
    }, [selectedAdvanceAccountId]);

    React.useEffect(() => {
        if (decorationData !== null && art !== null && personalizations !== null && legacy !== undefined) {
            let newSupData = [];
            let newPSupData = [];
            let data = decorationData;
            for (let a of art) {
                let temp = data?.artMap?.[a.imageFilename];
                if (!temp) {
                    temp = cloneDeep(defaultOption);
                    if (a.type === "emb") {
                        temp.options[0].stitchCount = a.stitchCount;
                    }
                }
                newSupData.push(temp);
            }
            console.log(art, newSupData);
            for (let p of personalizations) {
                let temp = data?.personalizationMap?.[p.name];
                if (!temp) {
                    temp = cloneDeep(defaultOption);
                }
                newPSupData.push(temp);
            }
            setSupData(newSupData);
            setPSupData(newPSupData);
            setLegacySupData(data.legacyData ?? cloneDeep(defaultOption));
            setActiveAuto(data.enabled ?? true);
            setDecoCostIncluded(data.decoCostIncluded ?? false);
            setSelectedAdvanceAccountId(data.advanceArtworkAccountId ?? null);
            setSelectedAdvanceAccountName(data.advanceArtworkAccountName ?? "");
        }
    }, [decorationData, art, personalizations, legacy]);

    const handleStyleDialogClose = () => {
        setOpenStyleDialog(false);
    }

    const submit = async () => {
        try {
            setSubmitting(true);
            let storeUrl = searchParams.get("inksoftStoreUrl");
            // const ref = doc(props.db, "domains", domainId, "inksoftDecorationData", storeUrl);
            let artMap = {};
            let personalizationMap = {};
            for (let i = 0; i < art.length; i++) {
                if (supData[i].active) {
                    artMap[art[i].imageFilename] = supData[i];
                }
            }
            for (const key of Object.keys(decorationData.artMap ?? {})) {
                if (!artMap[key]) {
                    artMap[key] = decorationData.artMap[key];
                }
            }
            for (let i = 0; i < personalizations.length; i++) {
                if (pSupData[i].active) {
                    personalizationMap[personalizations[i].name] = pSupData[i];
                }
            }
            await InksoftDecorationManager.setInksoftDecorationData(domainId, {
                'artMap': artMap,
                'personalizationMap': personalizationMap,
                "legacyData": legacySupData.active ? legacySupData : null,
                "enabled": activateAuto,
                "decoCostIncluded": decoCostIncluded,
                "advanceArtworkAccountId": selectedAdvanceAccountId,
                "advanceArtworkAccountName": advanceAccountInputValue,
                "inksoftStoreUrl": storeUrl
            })
            setSubmitting(false);
            setSaveMessage("Decoration Mapping Saved Successfully");
            setSubmitError(null);
        } catch (e) {
            console.log(e);
            setSubmitError(e.toString());
        } finally {
            setSubmitting(false);
        }
    };

    const handleStoreChange = (event) => {
        let newParams = new URLSearchParams(
            searchParams.toString()
        );
        newParams.set("inksoftStoreUrl", event.target.value);
        setSearchParams(newParams);
    }

    React.useEffect(() => {
        let disable = false;
        let allData = [...supData, ...pSupData, legacySupData];
        for (let data of allData) {
            if (data != null) {
                for (let option of data.options) {
                    if (option.advanceArtworkId != null) {
                        disable = true;
                    }
                }
            }
        }
        setDisabledAdvanceAccountSelected(disable);
    }, [supData, pSupData, legacySupData]);

    React.useEffect(() => {
        setSaveMessage(null);
    }, [supData, pSupData, legacySupData, activateAuto, selectedAdvanceAccountId, decoCostIncluded]);

    const handleSupDataUpdate = React.useCallback((index, data) => {
        setSupData(supData => {
            let newSupData = [...supData];
            newSupData[index] = data;
            return newSupData;
        });
    }, []);

    const handleClipboardUpdate = React.useCallback((options) => {
        setClipboard(cloneDeep(options));
    }, []);

    const handlePSupDataUpdate = React.useCallback((index, data) => {
        setPSupData(pSupData => {
            let newPSupData = [...pSupData];
            newPSupData[index] = data;
            return newPSupData;
        });
    }, []);

    const handleLegacyDataUpdate = React.useCallback((_, data) => {
        setLegacySupData(data);
    }, []);

    let loading = loadingDeco || loadingAccountArtwork || loadingSupData || loadingSuppliers || serviceData === null;
    return (
        <Container>
            <Stack
                direction='column'
                alignItems='center'
            >
                <StoreSelector value={searchParams.get("inksoftStoreUrl") ?? ""} handleStoreChange={handleStoreChange} />
                {loading &&
                    <Box sx={{ height: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CircularProgress />
                    </Box>
                }
                {!loading && error == null && searchParams.get("inksoftStoreUrl") !== null &&
                    <Stack
                        direction='column'
                        alignItems='center'
                    >
                        <Card>
                            <CardContent>
                                <Stack
                                    direction='column'
                                    gap={2}
                                >
                                    <FormControlLabel
                                        control={
                                            <Switch checked={activateAuto} onChange={(event, checked) => setActiveAuto(checked)} name="active" />
                                        }
                                        label="Enable Automatic Decoration Mapping"
                                    />
                                    <Autocomplete
                                        id="advance-account"
                                        disabled={disabledAdvanceAccountSelected}
                                        value={selectedAdvanceAccountId ?? null}
                                        getOptionLabel={(option) => option?.name ?? advanceAccounts.filter(a => a.id === option)?.[0]?.name ?? (selectedAdvanceAccountId === option ? selectedAdvanceAccountName : selectedAdvanceAccountId)}
                                        isOptionEqualToValue={(option, value) => option.id === value}
                                        options={advanceAccounts}
                                        loading={loadingAdvanceAccounts}
                                        filterOptions={(x) => x}
                                        onChange={(event, value) => {
                                            setSelectedAdvanceAccountId(value?.id ?? null);
                                            setSelectedAdvanceAccountName(value?.name);
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            setAdvanceAccountInputValue(newInputValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Advance Artwork Account"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {loadingAdvanceAccounts ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                    <Button variant="outlined" onClick={() => setOpenStyleDialog(true)}>
                                        View All Styles
                                    </Button>
                                    <LoadingButton loading={submitting} variant="contained" onClick={submit}>
                                        Save
                                    </LoadingButton>
                                    {saveMessage &&
                                        <Alert severity="success">{saveMessage}</Alert>
                                    }
                                    {submitError !== null &&
                                        <Alert severity="error">{submitError}</Alert>
                                    }
                                </Stack>
                            </CardContent>
                        </Card>
                        {supData.length !== 0 && art.length !== 0 && <Typography variant="h4" sx={{ paddingTop: "16px" }}>Decorated Products</Typography>}
                        {
                            supData.length !== 0 && art.map((a, idx) =>
                                <DecoManagementCard
                                    key={idx}
                                    data={a}
                                    idx={idx}
                                    suppliers={suppliers}
                                    artwork={advanceAccountArtwork}
                                    inksoftStoreUrl={searchParams.get("inksoftStoreUrl") ?? ""}
                                    supData={supData[idx]}
                                    handleUpdate={handleSupDataUpdate}
                                    clipboard={clipboard}
                                    handleClipboardUpdate={handleClipboardUpdate}
                                    serviceData={serviceData}
                                />
                            )
                        }
                        {supData.length !== 0 && personalizations.length !== 0 && <Typography variant="h4" sx={{ paddingTop: "16px" }}>Personalizations</Typography>}
                        {
                            pSupData.length !== 0 && personalizations.map((p, idx) =>
                                <DecoManagementCard
                                    key={idx}
                                    data={p}
                                    idx={idx}
                                    suppliers={suppliers}
                                    artwork={advanceAccountArtwork}
                                    inksoftStoreUrl={searchParams.get("inksoftStoreUrl") ?? ""}
                                    supData={pSupData[idx]}
                                    handleUpdate={handlePSupDataUpdate}
                                    clipboard={clipboard}
                                    handleClipboardUpdate={handleClipboardUpdate}
                                    serviceData={serviceData}
                                    personalization={true}
                                />)
                        }
                        {legacySupData !== null && legacy != null && <Typography variant="h4" sx={{ paddingTop: "16px" }}>Other</Typography>}
                        {
                            legacySupData !== null && legacy != null &&
                            <DecoManagementCard
                                data={legacy}
                                suppliers={suppliers}
                                artwork={advanceAccountArtwork}
                                inksoftStoreUrl={searchParams.get("inksoftStoreUrl") ?? ""}
                                supData={legacySupData}
                                handleUpdate={handleLegacyDataUpdate}
                                clipboard={clipboard}
                                handleClipboardUpdate={handleClipboardUpdate}
                                serviceData={serviceData}
                            />
                        }
                    </Stack>
                }
                {error !== null &&
                    <Alert sx={{ margin: "16px" }} severity="error">{error}</Alert>
                }
            </Stack>
            {
                !loading &&
                <DecoStyleTable open={openStyleDialog} suppliers={suppliers} handleClose={handleStyleDialogClose} showSyncoreFields={serviceData.syncoreFields} artwork={advanceAccountArtwork} />
            }
        </Container>
    );
}

export default DecoManagerPage;